import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { m, LazyMotion, domAnimation, useAnimation } from "framer-motion";
import { unwidower } from "@utils";
import useAnnouncementData from "@staticQueries/AnnouncementQuery";

import { Button, Container, Icon, Text } from "@atoms";
import { AppLink } from "@base";

const Announcement = () => {
  const [close, setClose] = useState(false);
  const bannerControls = useAnimation();
  const { enabled, heading, copy, button } = useAnnouncementData();

  useEffect(() => {
    if (close) {
      bannerControls.start({
        height: 0,
        transition: {
          type: "tween",
          ease: "easeInOut",
          duration: 0.3,
        },
        transitionEnd: {
          display: "none",
        },
      });
    } else {
      bannerControls.start({
        height: "auto",
        transition: {
          type: "tween",
          ease: "easeInOut",
          duration: 0.3,
        },
      });
    }
  }, [close]);

  if (enabled) {
    return (
      <LazyMotion features={domAnimation}>
        <m.div
          initial={{ height: 0 }}
          animate={bannerControls}
          className={classNames(
            "relative z-10 w-full overflow-hidden bg-mint",
            { "pointer-events-none": close },
            { "pointer-events-auto": !close }
          )}
        >
          <Container>
            <div className="flex flex-wrap items-center justify-between gap-4 py-2 md:flex-nowrap md:gap-8">
              <div className="flex flex-grow flex-wrap items-baseline gap-2 text-midnight md:flex-nowrap md:gap-8">
                {heading && (
                  <Text className="font-heading text-base leading-none">
                    {heading}
                  </Text>
                )}
                <Text
                  richText
                  className="text-midnight prose-p:text-xs prose-p:leading-snug"
                >
                  {unwidower(copy)}
                </Text>
              </div>
              <div className="flex flex-shrink-0 items-center justify-between gap-4 md:w-auto md:justify-center">
                {button.url && (
                  <Button
                    to={button.url}
                    size="xs"
                    color="midnight"
                    onClick={() => setClose(true)}
                  >
                    {button.text}
                  </Button>
                )}
                <AppLink
                  className="flex items-center"
                  noBottomPadding
                  onClick={() => setClose(true)}
                >
                  <Icon
                    name="close"
                    className="h-4 w-4 text-midnight duration-300 hover:text-white"
                  />
                </AppLink>
              </div>
            </div>
          </Container>
        </m.div>
      </LazyMotion>
    );
  }
  return null;
};

export default Announcement;
